export enum Screen
{
    None,
    Home,
    Intro,
    AboutMe,
    Interests,
    End,
    GitHub,
    Resume,
}