import React, { useState } from 'react';
import { Screen } from '../constants/Constants';

interface InterestsComponentProps
{
    handleSwitchScreenFunc: (inScreen: Screen) => void;
}

const InterestsComponent: React.FC<InterestsComponentProps> = ({ handleSwitchScreenFunc }) =>
{
    const handleClick = () =>
    {
        handleSwitchScreenFunc(Screen.End);
    }

    return (
        <div className="aboutme-dropdown" onClick={handleClick} onTouchStart={handleClick}>
            <h3>
                I play a lot of video games
            </h3>
            <h4>
                Some more than others
            </h4>
            {/* <button onClick={handleClick}>Back to Intro</button> */}
            <h3 style = {{ color: 'orange'}}>Click Text To Continue</h3>
        </div>
    );
};

export default InterestsComponent;