import React from 'react';
import logo from './logo.svg';
import './App.css';

// theme import
import SpookyTheme from './themes/SpookyTheme';

function App() {
  return (
    // set the theme here based on what theme it should be
    <SpookyTheme />
  );
}

export default App;
