import React, { useState } from 'react';
import { Screen } from '../constants/Constants';
import './../styles/ButtonStyles.css';

interface HomeComponentProps
{
    handleSwitchScreenFunc: (inScreen: Screen) => void;
}

const HomeComponent: React.FC<HomeComponentProps> = ({ handleSwitchScreenFunc }) =>
{
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleStartExperience = () =>
    {
        handleSwitchScreenFunc(Screen.AboutMe);
    }

    const handleRedirectExternal = (inURL: string) =>
    {
        window.open(inURL);
    }

    const handleGitHubChosen = () =>
    {
        handleRedirectExternal("https://github.com/NathanBarquira");
    }

    const handleResumeChosen = () =>
    {
        handleSwitchScreenFunc(Screen.Resume);
    }

    const handleLinkedInChosen = () =>
    {
        handleRedirectExternal("https://www.linkedin.com/in/nathan-barquira-1a65861bb/")
    }

    return (
        <div className="aboutme-dropdown">
            <h1 className="header-text">HEDOOO DERR!!!</h1>
            <h3>
                My Name is Nathan Barquira.<br />
                Welcome to my website!
            </h3>
            <h4>
                Here are a few quick links to get quick info:
            </h4>
            <div>
                <button onClick={handleGitHubChosen} className="styled-button-spooky">
                    GitHub
                </button>
                <button onClick={handleResumeChosen} className="styled-button-spooky">
                    Resume
                </button>
                <button onClick={handleLinkedInChosen} className="styled-button-spooky">
                    LinkedIn
                </button>
            </div>

            <div>
                <p> and if you want to get fancy... here's a cinematic experience button...</p>
                <button onClick={handleStartExperience} className="styled-button-special-spooky">
                    Cinematic Experience
                </button>
            </div>
        </div>
    );
};

export default HomeComponent;