import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Screen } from './../constants/Constants';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

// component imports
import AboutMeComponent from './AboutMeComponent';
import InterestsComponent from './InterestsComponent';
import EndReachedComponent from './EndReachedComponent';
import HomeComponent from './HomeComponent';
import ResumeComponent from './ResumeComponent'

// css imports
import './../styles/Transitions.css';

interface ScreenSwitcherProps {}

export interface ScreenSwitcherRef
{
    handleSwitchScreen: (inScreen: Screen) => void;
}

const ScreenSwitcher = forwardRef<ScreenSwitcherRef, ScreenSwitcherProps>((props, ref) =>
{
    const [currentScreen, setCurrentScreen] = useState<Screen>(Screen.Home);

    // forward
    useImperativeHandle(ref, () => 
        (
            {
                handleSwitchScreen,
            }
        ));

    const renderScreen = () =>
    {
        switch (currentScreen)
        {
            case Screen.Home:
            {
                return <HomeComponent handleSwitchScreenFunc={handleSwitchScreen}/>;
            }
            case Screen.AboutMe:
            {
                return <AboutMeComponent handleSwitchScreenFunc={handleSwitchScreen}/>;
            }
            case Screen.Interests:
            {
                return <InterestsComponent handleSwitchScreenFunc={handleSwitchScreen}/>;
            }
            case Screen.End:
            {
                return <EndReachedComponent handleSwitchScreenFunc={handleSwitchScreen}/>;
            }
            case Screen.GitHub:
            {
                return 
            }
            case Screen.Resume:
            {
                return <ResumeComponent handleSwitchScreenFunc={handleSwitchScreen}/>;
            }
            default:
            {
                return <HomeComponent handleSwitchScreenFunc={handleSwitchScreen}/>;
            }
        }
    }

    const handleSwitchScreen = (inScreen: Screen) =>
    {
        if (inScreen === currentScreen) return;

        setCurrentScreen(inScreen);
    }

    return (
        
        <div>
            <SwitchTransition mode="out-in">
                <CSSTransition key={currentScreen} timeout={500} classNames="fade" unmountOnExit>
                    <div>{renderScreen()}</div>
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
});

export default ScreenSwitcher;