import React, { useState } from 'react';
import { Screen } from '../constants/Constants';

interface EndReachedComponentProps
{
    handleSwitchScreenFunc: (inScreen: Screen) => void;
}

const EndReachedComponent: React.FC<EndReachedComponentProps> = ({ handleSwitchScreenFunc }) =>
{
    const handleClick = () =>
    {
        handleSwitchScreenFunc(Screen.Intro);
    }

    return (
        <div className="aboutme-dropdown" onClick={handleClick} onTouchStart={handleClick}>
            <h3>
                You've reached the end of the click cycle... for now 😊
            </h3>
            <h4>
                Thanks for reading. This website is still WIP.
            </h4>
            <h3 style = {{ color: 'orange'}}>Click Text To Restart Loop</h3>
        </div>
    );
};

export default EndReachedComponent;