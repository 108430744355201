import React, { useState, useRef } from 'react';
import { Screen } from '../constants/Constants';

// component import
import ScreenSwitcher, { ScreenSwitcherRef } from './../components/ScreenSwitcher';
import CurrentThemeComponent from '../components/CurrentThemeComponent';
import BackHomeComponent from './../components/BackHomeComponent';

const SpookyTheme = () =>
{

    const screenSwitcherRef = useRef<ScreenSwitcherRef>(null);

    const switchScreenFromExternal = (inScreen: Screen) =>
    {
        if (screenSwitcherRef.current)
        {
            screenSwitcherRef.current.handleSwitchScreen(inScreen);
        }
    }

    return (
        <div className="App">
            <CurrentThemeComponent themeName='Spooky'/>
            <BackHomeComponent handleSwitchScreenFunc={switchScreenFromExternal} />
        <header className="App-body">
            <ScreenSwitcher ref={screenSwitcherRef}/>  
        </header>
        <header className="App-fillspace"></header>
        </div>
    );
};

export default SpookyTheme;