import React, { useState } from 'react';
import { Screen } from '../constants/Constants';

interface AboutMeComponentProps
{
    handleSwitchScreenFunc: (inScreen: Screen) => void;
}

const AboutMeComponent: React.FC<AboutMeComponentProps> = ({ handleSwitchScreenFunc }) =>
{
    const handleClick = () =>
    {
        handleSwitchScreenFunc(Screen.Interests);
    }

    return (
        <div className="aboutme-dropdown-content" onClick={handleClick} onTouchStart={handleClick}>
            <p>
                So yeah my name is Nathan Barquira...
            </p>
            <p>
                I love math and I love playing and making video games.
            </p>
            {/* <button onClick={handleClick}>Continue</button> */}
            <h3 style = {{ color: 'orange'}}>Click Text To Continue</h3>
        </div>
    );
};

export default AboutMeComponent;