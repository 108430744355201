import React, { useState } from 'react';
import { Screen } from '../constants/Constants';
import Resume from './../assets/resumes/NathanBarquiraResume2024.pdf'

interface ResumeComponentProps
{
    handleSwitchScreenFunc: (inScreen: Screen) => void;
}

const ResumeComponent: React.FC<ResumeComponentProps> = ({ handleSwitchScreenFunc }) =>
{
    const handleClick = () =>
    {
        handleSwitchScreenFunc(Screen.End);
    }

    return (
        <div>
            <iframe src={Resume} 
            style = 
            {{ 
                position: 'absolute',
                top: 200,
                left: 0,
                width: '100%', 
                height: '100vh', 
                zIndex: 1000,
                border: 'none',
                overflow: 'hidden',
            }}/>
        </div>
    );
};

export default ResumeComponent;