import React, { useState } from 'react';

interface CurrentThemeComponentProps
{
    themeName: string;
}

const CurrentThemeComponent: React.FC<CurrentThemeComponentProps> = ({ themeName }) =>
{
    return (
        <header className="App-header">
        <p>
            Current Theme = { themeName }
        </p>
        </header>
    );
};

export default CurrentThemeComponent;