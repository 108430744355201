import React, { useState } from 'react';
import { Screen } from '../constants/Constants';

interface BackHomeComponentProps
{
    handleSwitchScreenFunc: (inScreen: Screen) => void;
}

const BackHomeComponent: React.FC<BackHomeComponentProps> = ({ handleSwitchScreenFunc }) =>
{
    const handleClick = () =>
    {
        handleSwitchScreenFunc(Screen.Home);
    }

    return (
        <header className="App-header">
            <button className="styled-button-spooky" onClick={handleClick}> Back Home </button>
        </header>
    );
};

export default BackHomeComponent;